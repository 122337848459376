<!--
 * @Description: 查看人才简历
 * @Author: huacong
 * @Date: 2021-07-05 09:32:17
 * @LastEditTime: 2021-07-05 09:44:42
 * @LastEditors: huacong
-->
<template>
  <div>
    <resumePre></resumePre>
  </div>
</template>
<script>
import resumePre from "@/view/talentPage/resumePre";
export default {
  components: {
    resumePre,
  },
};
</script>
